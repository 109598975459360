import React, { useCallback } from "react";
import { FormikErrors, FormikHandlers, FormikTouched, useFormikContext } from "formik";
import { ImagePreview, SelectField } from "@/components";
import { ROUTES } from "@/config";
import { IEntry } from "@/types";
import { TextField } from "@material-ui/core";

const RemoveButton: React.FC<{ onClick(): void; className?: string }> = ({ onClick, className }) => (
  <button type="button" className={className} onClick={onClick}>
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
      <path
        d="M13 1.30929L11.6907 0L6.5 5.19072L1.30929 0L0 1.30929L5.19071 6.5L0 11.6907L1.30929 13L6.5 7.80929L11.6907 13L13 11.6907L7.80929 6.5L13 1.30929Z"
        fill="currentColor"
      />
    </svg>
  </button>
);

interface Props {
  index: number;
  categoryOptions: JSX.Element[];
  values: IEntry;
  onChange: FormikHandlers["handleChange"];
  onBlur: FormikHandlers["handleBlur"];
  touched?: FormikTouched<IEntry>;
  errors?: FormikErrors<IEntry>;
  onRemove?: (index: number) => void;
}

const Entry: React.FC<Props> = ({ index, categoryOptions, values, touched, errors, onChange, onBlur, onRemove }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const onRemoveEntry = useCallback(() => onRemove?.(index), [index, onRemove]);
  const setImage = useCallback(
    (file?: File) => {
      const field = `entries.${index}.file`;
      setFieldTouched(field, true);
      setFieldValue(field, file);
    },
    [index, setFieldValue, setFieldTouched],
  );

  const entryIndex = index + 1;

  return (
    <fieldset id={`entry-${entryIndex}`} className="pt-10 mt-8 lg:mt-20">
      <div className="pt-8 border-t-4 border-white lg:grid lg:gap-30 lg:grid-cols-[1fr_1.72fr]">
        <div className="flex flex-col gap-7">
          <div className="flex items-end justify-between">
            <h2 className="text-40 leading-none">Entry {entryIndex}</h2>
            {onRemove && (
              <button
                type="button"
                onClick={onRemoveEntry}
                className="mb-1 text-11 text-robot-friend tracking-tight underline transition-colors hover:text-white"
              >
                Remove entry {entryIndex}
              </button>
            )}
          </div>

          <SelectField
            name={`entries.${index}.category`}
            id={`entry-form-entries-${index}-category`}
            label="Category"
            value={values.category}
            error={touched?.category && !!errors?.category}
            helperText={touched?.category && errors?.category}
            onChange={onChange}
            onBlur={onBlur}
            fullWidth
          >
            <option aria-label="None" disabled />
            {categoryOptions}
          </SelectField>

          <TextField
            name={`entries.${index}.title`}
            id={`entry-form-entries-${index}-title`}
            label="Image title"
            type="text"
            value={values.title}
            error={touched?.title && !!errors?.title}
            helperText={touched?.title && errors?.title}
            onChange={onChange}
            onBlur={onBlur}
            fullWidth
          />

          <TextField
            name={`entries.${index}.description`}
            id={`entry-form-entries-${index}-description`}
            label="Image description (optional)"
            type="text"
            value={values.description}
            error={touched?.description && !!errors?.description}
            helperText={touched?.description && errors?.description}
            onChange={onChange}
            onBlur={onBlur}
            fullWidth
            multiline
          />

          {values.file && !errors?.file && (
            <div className="relative">
              <TextField
                label="Image"
                value={values.file.name}
                disabled
                fullWidth
                inputProps={{ style: { paddingRight: "2rem" } }}
              />
              <RemoveButton
                onClick={() => setImage(undefined)}
                className="absolute top-[17px] right-0 p-1.5 text-robot-friend transition-colors hover:text-white"
              />
            </div>
          )}

          <p className="text-11 text-robot-friend">
            By submitting your entry you accept our{" "}
            <a className="underline transition-colors hover:text-white" href={`/${ROUTES.AWARDS.TERMS.SLUG}`}>
              awards terms and conditions
            </a>
            .
          </p>
        </div>

        <div className="pt-8">
          <ImagePreview
            index={index}
            file={values.file}
            setImage={setImage}
            error={touched?.file && errors?.file ? errors.file : undefined}
          />
        </div>
      </div>
    </fieldset>
  );
};

export default Entry;
