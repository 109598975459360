import classNames from "classnames";
import { Section, Container, HtmlToElement } from "@/components";
import { pageFields, PageFragmentType } from "@/config";
import { createHref } from "@/utils";
import { HorizontalRule } from "@narrative-software/narrative-web-ui";
import { MODULE_KEYS } from "./config";

export interface ResourceFragmentType {
  _modelApiKey: typeof MODULE_KEYS.resource;
  id: string;
  items: ResourceItemFragmentType[];
  heading?: React.ReactNode;
  headingSize?: "h1" | "h2" | "h3";
  subheading?: React.ReactNode;
  footer?: React.ReactNode;
  isSection?: boolean;
}

export interface ResourceItemFragmentType {
  id: string;
  title: string;
  url?: string;
  newTab?: boolean;
  internal?: boolean;
  page?: PageFragmentType;
}

// Fragment
export const resourceFragment = `
  fragment resourceFragment on ModuleResourceRecord {
    _modelApiKey
    id
    heading
    headingSize
    subheading
    isSection
    items {
      id
      title
      url
      newTab
      internal
      page {
        ${pageFields}
      }
    }
  }
`;

export const Resources: React.FC<ResourceFragmentType> = ({
  id,
  heading,
  headingSize,
  subheading,
  items,
  footer = null,
}) => (
  <Section key={id} id={id}>
    <Container>
      {heading && (
        <div className="flex flex-col items-center mb-6 text-center md:mb-10">
          <HorizontalRule bottomMargin />
          <HtmlToElement
            element={headingSize}
            className={classNames("font-semibold leading-tight", {
              "text-h2 lg:text-h1": headingSize === "h1",
              "text-h3 lg:text-h2": headingSize === "h2",
              "text-h4 lg:text-h3": headingSize === "h3",
            })}
          >
            {heading}
          </HtmlToElement>
          {subheading && <HtmlToElement className="max-w-200 mt-8 lg:text-lg">{subheading}</HtmlToElement>}
        </div>
      )}

      {!!items.length && (
        <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          {items.map(({ id, title, url, newTab, internal, page }) => {
            const label = (
              <span className="absolute inset-0 flex items-center justify-center px-4 text-22 text-center">
                {title}
              </span>
            );
            const className =
              "relative block h-0 pb-1/2 border border-black bg-transparent overflow-hidden transition-color duration-200 hover:bg-white hover:underline";

            // Internal
            if (internal && page) {
              const href = createHref(page);
              return (
                <a className={className} key={id} href={href}>
                  {label}
                </a>
              );
            }

            let target;
            let rel;

            if (newTab) {
              target = "_blank";
              rel = "noreferrer noopener";
            }

            // External
            return (
              <a key={id} href={url} target={target} rel={rel} className={className}>
                {label}
              </a>
            );
          })}
        </div>
      )}

      {footer}
    </Container>
  </Section>
);
