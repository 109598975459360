import { NavItem } from "..";

type Props = {
  items: NavItem[];
};

const DropdownNav: React.FC<Props> = ({ items }) => (
  <>
    <div className="dropdown hidden absolute top-8/10 left-1/2 -translate-x-1/2 py-4 opacity-0 invisible bg-stealth-bomber text-center transition-all group-hover:opacity-100 group-hover:visible lg:block">
      <ul>
        {items.map(({ href, label, isExternal, isNewTab }) => {
          const Anchor = (
            <a
              href={href}
              target={isNewTab ? "_blank" : undefined}
              rel={isNewTab ? "noreferrer noopener" : undefined}
              className="block px-5 py-0.5 text-14 text-cinder-block leading-relaxed underline decoration-transparent transition-colors hover:text-white hover:decoration-white"
            >
              {label}
            </a>
          );

          return <li key={label}>{Anchor}</li>;
        })}
      </ul>
    </div>
    <style jsx>{`
      .dropdown::before {
        content: "";
        position: absolute;
        top: -7px;
        left: 50%;
        width: 0;
        height: 0;
        pointer-events: none;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #1c1c1c;
        transform: translateX(-50%);
      }
    `}</style>
  </>
);

export default DropdownNav;
