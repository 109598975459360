import classNames from "classnames";
import { AnchorButton, ButtonAppearance, ButtonColour } from "@narrative-software/narrative-web-ui";

const ProductSummary = ({
  title,
  leadLine,
  children,
  ctas,
  headingLevel = "h2",
}: {
  title: string;
  leadLine?: string;
  children: React.ReactNode;
  ctas: { text: string; url: string; buttonColor: ButtonColour; buttonAppearance: ButtonAppearance }[];
  headingLevel?: "h1" | "h2";
}) => (
  <div className="flex flex-col justify-center text-center lg:text-left mt-12 md:mt-0 mb-15 md:p-10 lg:p-0 md:mb-0 max-w-[452px] px-4 sm:px-0">
    {headingLevel === "h2" ? (
      <h2 className="mb-5 text-h2 leading-[108%] font-semibold lg:text-36 md:text-24 w-full">
        {leadLine && <span className="font-light block">{leadLine}</span>}
        {title}
      </h2>
    ) : (
      <h1 className="mb-5 text-h2 leading-[108%] font-semibold lg:text-48 md:text-32 w-full">
        {leadLine && <span className="font-light block">{leadLine}</span>}
        {title}
      </h1>
    )}

    <p className="mb-7 max-w-[98%]">{children}</p>
    <div
      className={classNames("flex flex-col gap-4 xl:flex-row", {
        "xl:min-w-[552px]": ctas.length > 1,
      })}
    >
      {ctas.map((cta) => (
        <AnchorButton
          colour={cta.buttonColor}
          className="md:max-w-[380px]"
          appearance={cta.buttonAppearance}
          key={`${cta.text.replaceAll(" ", "")}_${cta.url}`}
          href={cta.url}
        >
          {cta.text}
        </AnchorButton>
      ))}
    </div>
  </div>
);

export default ProductSummary;
