import {
  Image as DatoImage,
  StructuredText as DatoCmsStructuredText,
  StructuredTextGraphQlResponse,
} from "react-datocms";
import { Video } from "@/components";
import { formatImageData, pageFields } from "@/config";
import { DatoButton } from "@/modules";
import { createHref } from "@/utils";
import { buttonFields } from "./button";

export const structuredTextFields = `
  value
  links {
    __typename
    ${pageFields}
  }
  blocks {
    __typename
    ... on LinkRecord {
      ${buttonFields}
    }
    ... on ImageRecord {
      id
      image {
        responsiveImage(imgixParams: { auto: format }) {
          ...responsiveImageFragment
        }
      }
    }
    ... on VideoRecord {
      id
      isVideoFile
      videoUrl {
        url
        width
        height
      }
      videoFile {
        url
        width
        height
        video {
          streamingUrl
          mp4Url(res: high)
          thumbnailUrl(format: jpg)
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const StructuredText: React.FC<{ data: StructuredTextGraphQlResponse<any> }> = ({ data }) => (
  <DatoCmsStructuredText
    data={data}
    renderLinkToRecord={({ record, children, transformedMeta }) => {
      if (!children) return null;
      return (
        <a {...transformedMeta} href={createHref(record)}>
          {children}
        </a>
      );
    }}
    renderInlineRecord={({ record }) => {
      return <a href={createHref(record)}>{record.title}</a>;
    }}
    renderBlock={({ record }) => {
      switch (record.__typename) {
        case "LinkRecord": {
          return <DatoButton {...record} className="sm:max-w-60" />;
        }
        case "ImageRecord": {
          return <DatoImage data={formatImageData({ image: record.image })} />;
        }
        case "VideoRecord": {
          const { videoUrl, videoFile, isVideoFile } = record;

          let url;
          let aspectRatio;

          if (videoUrl) {
            const { width, height } = videoUrl;
            url = videoUrl.url;
            aspectRatio = width / height;
          }

          if (isVideoFile && videoFile) {
            const { width, height } = videoFile;
            url = videoFile.video.streamingUrl;
            aspectRatio = width / height;
          }

          if (!url) return null;

          return (
            <div className="my-8 border border-black">
              <Video {...{ url, aspectRatio, isVideoFile }} />
            </div>
          );
        }
        default:
          return null;
      }
    }}
  />
);
