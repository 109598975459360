import { Image as DatoImage } from "react-datocms";
import { ROUTES } from "@/config";
import { IMAGE_OBJECT_POSITION } from "@/constants";
import { Artist, ArtistStyle } from "@/types";

type StyleTileProps = Pick<ArtistStyle, "slug" | "name" | "description" | "image1After"> & { artist: Artist };

function StyleTile({ slug, name, description, image1After, artist }: StyleTileProps) {
  return (
    <a href={`/${ROUTES.EDIT.STYLES.SLUG}/${slug}`}>
      <div className="text-center cursor-pointer hover:bg-off-white transition-colors	bg-transparent">
        <div className="relative h-0 w-full pb-[57.3%]">
          <DatoImage
            data={{ ...image1After.responsiveImage }}
            style={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0 }}
            objectFit="cover"
            objectPosition={IMAGE_OBJECT_POSITION}
          />
          <div className="absolute right-[10px] bottom-[-20px] w-[70px] h-[70px] rounded-full overflow-hidden">
            <DatoImage
              data={{ ...artist.profilePicture.responsiveImage }}
              style={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0 }}
              objectFit="cover"
              objectPosition={IMAGE_OBJECT_POSITION}
            />
          </div>
        </div>
        <div className="px-[10px] mt-7 mb-8">
          <h3 className="text-h3 font-semibold leading-snug">
            {name}
            <br />
            <span className="font-normal">{artist.name}</span>
          </h3>
          <p className="mt-4">{description}</p>
        </div>
      </div>
    </a>
  );
}

export { StyleTile };
