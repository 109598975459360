import { NavItemPrimary } from "..";
import classNames from "classnames";

type Props = {
  items: NavItemPrimary[];
  activePath: string;
  onClose: () => void;
  open?: boolean;
  className?: string;
};

const MobileSecondaryNav: React.FC<Props> = ({ items, activePath, onClose, open = false, className = "" }) => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (event.currentTarget.pathname === activePath) {
      onClose();
    }
  };

  return (
    <ul
      className={classNames("mb-4", {
        block: open,
        hidden: !open,
      })}
    >
      {items.map(({ secondaryNav, ...rest }) => {
        const getLink = ({ href, label, isActive, isExternal, isNewTab, hideMobile }: NavItemPrimary) => {
          const Anchor = (
            <a
              href={href}
              target={isNewTab ? "_blank" : undefined}
              rel={isNewTab ? "noreferrer noopener" : undefined}
              onClick={handleClick}
              className={classNames(className, "py-1.5 text-xs", {
                "text-gray-500": !isActive,
                "text-white underline": isActive,
              })}
            >
              {label}
            </a>
          );

          return (
            <li key={label} className={classNames({ "hidden lg:block": hideMobile })}>
              {Anchor}
            </li>
          );
        };

        if (secondaryNav?.length) {
          return secondaryNav.map((item) => getLink(item));
        }

        return getLink(rest);
      })}
    </ul>
  );
};

export default MobileSecondaryNav;
