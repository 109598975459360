import { Image as DatoImage } from "react-datocms";
import { NewsletterForm, SignUpForm, StartFreeButtons } from "@/components";
import { ROUTES, formatImageData, IS_GOOGLE_AUTH_ENABLED } from "@/config";
import { ImageType } from "@/types";
import { PromoBanner as UIPromoBanner } from "@narrative-software/narrative-web-ui";
import { ButtonFragmentType, DatoButton } from "./button";
import { MODULE_KEYS } from "./config";

export interface PromoBannerFragmentType {
  _modelApiKey: typeof MODULE_KEYS.promoBanner;
  id: string;
  heading: string;
  description?: string;
  size?: "medium" | "large";
  textColour?: "white" | "black";
  backgroundColour?: "gengar" | "ekans" | "magmar" | "slowpoke" | "charizard" | "pikachu";
  form?: "sign_up" | "newsletter";
  showWindowsLink?: boolean;
  imageAlignment?: "left" | "right";
  callToAction?: ButtonFragmentType[];
  image?: ImageType;
  className?: string;
  isSection?: undefined;
}

// Fragment
export const promoBannerFragment = `
  fragment promoBannerFragment on ModulePromoBannerRecord {
    _modelApiKey
    id
    heading
    description
    size
    textColour
    backgroundColour
    form
    showWindowsLink
    image {
      url
      alt
      width
      height
      responsiveImage(imgixParams: { auto: format }) {
        ...responsiveImageFragment
      }
    }
    imageAlignment
    callToAction {
      ...buttonFragment
    }
  }
`;

export const PromoBanner: React.FC<PromoBannerFragmentType> = ({
  id,
  heading,
  size,
  form,
  image,
  callToAction,
  showWindowsLink,
  ...rest
}) => {
  const product = rest.backgroundColour === "slowpoke" ? "publish" : "select";

  const footerText = showWindowsLink && (
    <a className="hover:underline" href={`/${ROUTES.WINDOWS.SLUG}`}>
      Looking for Windows?
    </a>
  );

  // Image
  const imageComponent = image && (
    <DatoImage
      data={formatImageData({ image })}
      pictureClassName="max-h-60 md:max-h-80"
      pictureStyle={{ width: "auto" }}
      fadeInDuration={0}
      priority
    />
  );

  // Form
  let formComponent;
  switch (form) {
    case "sign_up":
      formComponent = IS_GOOGLE_AUTH_ENABLED ? (
        <StartFreeButtons product={product} className="max-w-130" />
      ) : (
        <SignUpForm product={product} className="max-w-150" textClassName="text-white" />
      );
      break;
    case "newsletter":
      formComponent = <NewsletterForm className="max-w-150" textClassName="text-white" />;
  }

  return (
    <UIPromoBanner
      key={id}
      title={heading}
      image={imageComponent}
      form={formComponent}
      large={size === "large"}
      {...{ footerText, ...rest }}
    >
      {callToAction?.map((props) => <DatoButton key={props.id} {...props} />)}
    </UIPromoBanner>
  );
};
