import { ROUTES } from "@/config";
import { AnchorButton, PromoBanner } from "@narrative-software/narrative-web-ui";

const BottomPromoSaleBanner = () => (
  <PromoBanner
    title={<div className="w-80">Black Friday Sale! Up to 50% off</div>}
    description={<>Get started and tell your photography stories </>}
    form={
      <AnchorButton className="mt-2 sm:w-1/2 sm:mt-0" colour={"slowpoke"} href={`/${ROUTES.PUBLISH.PRICING.SLUG}`}>
        Shop sale
      </AnchorButton>
    }
    backgroundImagePath={`/images/bottom-banner-bg.png`}
    imageAlignment="left"
    large={false}
  />
);

export default BottomPromoSaleBanner;
