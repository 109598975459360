import classNames from "classnames";
import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Api } from "@/api";
import { IS_DEVELOPMENT, ROUTES } from "@/config";
import { useAlertDispatch } from "@/providers";
import { mockApiRequest } from "@/utils";
import { Button, ButtonColour, AlertActionType } from "@narrative-software/narrative-web-ui";

const hiddenInputs = {
  u: "26",
  f: "26",
  s: "",
  c: "0",
  m: "0",
  act: "sub",
  v: "2",
  or: "e88f2df0080f8960f79283e91d047ed5",
};

// Types
interface Fields {
  email: string;
}

// Initial values
const initialValues: Fields = {
  email: "",
};

// Validation to restrict submission, but field will show native browser validation messages
const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email required"),
});

type Props = {
  hideLegal?: boolean;
  className?: string;
  textClassName?: string;
  buttonColour?: ButtonColour;
};

const NewsletterForm: React.FC<Props> = ({ hideLegal, className, textClassName, buttonColour = "black" }) => {
  const alertDispatch = useAlertDispatch();

  // Handle submit
  const handleSubmit = async ({ email }: Fields, actions: FormikHelpers<Fields>) => {
    try {
      const response = IS_DEVELOPMENT
        ? await mockApiRequest()
        : await Api.sendActiveCampaignsForm({ ...hiddenInputs, email });

      // Check response
      if (response.status >= 200 && response.status < 300) {
        alertDispatch({
          type: AlertActionType.SetContent,
          payload: {
            type: "success",
            title: "You have been successfully subscribed.",
          },
        });
        actions.resetForm();
      } else {
        throw new Error();
      }
    } catch {
      alertDispatch({
        type: AlertActionType.SetContent,
        payload: {
          type: "error",
          title: "There was an error submitting your email. Please try again.",
        },
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <div className={classNames("w-full", className)}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ values, handleChange, handleBlur, isSubmitting, dirty }) => (
          <Form className="relative">
            <div className="flex flex-col sm:flex-row">
              <input
                name="email"
                type="email"
                className="block h-13.75 px-5 border border-gray-200 sm:w-2/3 sm:border-r-0"
                placeholder="Enter your email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
              <Button
                type="submit"
                colour={buttonColour}
                isLoading={isSubmitting}
                showLoader
                className="mt-2 sm:w-1/3 sm:mt-0 w-64"
                style={{ paddingLeft: "12px", paddingRight: "12px" }}
              >
                Subscribe
              </Button>
            </div>

            <p
              className={classNames(
                "w-full mt-4 text-12 transition-opacity duration-200 lg:absolute lg:top-18 lg:mt-0",
                textClassName,
                {
                  hidden: hideLegal,
                  "lg:opacity-0": !dirty,
                  "text-shady-lady": !textClassName,
                },
              )}
            >
              By submitting your email address you accept our{" "}
              <a className="underline" href={`/${ROUTES.TERMS.SLUG}`}>
                terms
              </a>{" "}
              and{" "}
              <a className="underline" href={`/${ROUTES.PRIVACY.SLUG}`}>
                privacy policy
              </a>
              .
            </p>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewsletterForm;
