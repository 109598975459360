import { DropdownNav } from ".";
import { NavItemPrimary } from "..";
import classNames from "classnames";

type Props = {
  items: NavItemPrimary[];
};

const DesktopNav: React.FC<Props> = ({ items }) => (
  <ul className="hidden -mr-4 underline-offset-[3px] lg:flex">
    {items.map(({ href, label, isActive, isExternal, isNewTab, secondaryNav }) => {
      const Anchor = (
        <a
          href={href}
          target={isNewTab ? "_blank" : undefined}
          rel={isNewTab ? "noreferrer noopener" : undefined}
          className={classNames(
            "flex items-center px-3 xl:px-4 text-white text-14 tracking-medium text-center underline decoration-transparent cursor-pointer transition-all group-hover:opacity-100 group-hover:decoration-white focus:opacity-100 focus:decoration-white",
            {
              "opacity-70": !isActive,
              "opacity-100 font-semibold": isActive,
            },
          )}
        >
          {label}
        </a>
      );

      return (
        <li key={label} className="group relative flex">
          {Anchor}
          {secondaryNav && <DropdownNav items={secondaryNav} />}
        </li>
      );
    })}
  </ul>
);

export default DesktopNav;
